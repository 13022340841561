import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import "./GroupBy.css";
import TabBubble from "../TableTabs/TabBubble";

const GroupBy = (props: any) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelect = (selected: any) => {
    setSelectedFilters(selected);
    props.selectedFilter(selected);
  };

  const handleApply = () => {
    props.onApply();
    setDropdownOpen(false);
  };

  const handleClear = () => {
    setSelectedFilters([]);
    props.selectedFilter([]);
  };

  return (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
        setDropdownOpen(false);
      }}
      className="groupBy"
      style={{ top: props.y, left: props.x - 200 }}>
      <div className="groupByHeader">
        <div className="groupTitle">
          <img src="Group List.png" alt="group" />
          <span>Group by</span>
        </div>
        <img
          style={{ height: "16px", width: "16px" }}
          src="Dismiss.png"
          alt="dismiss"
          onClick={props.onDismiss}
        />
      </div>
      <Dropdown
        multiselect={true}
        onSelect={handleSelect}
        filterOptions={props.options}
        open={dropdownOpen}
        setOpen={setDropdownOpen}
        selectedOptions={props.selectedFilters}
      />
      <div className="groupByButtons">
        <TabBubble name="Apply" selected="Apply" onClick={handleApply} />
        <TabBubble name="Clear" selected="" onClick={handleClear} />
      </div>
    </div>
  );
};

export default GroupBy;
