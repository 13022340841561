import "./TableTabs.css";
import TabBubble from "./TabBubble";
import { useEffect, useState } from "react";
import SavePopup from "../SavePopup/SavePopup";

const TableTabs = (props: any) => {
  const [saveOpen, setSaveOpen] = useState(false);
  const [selected, setSelected] = useState<any>("");

  useEffect(() => {}, []);

  return (
    <>
      {saveOpen && (
        <SavePopup
          onQuit={(e: any) => {
            e.preventDefault();
            if (selected) {
              props.setSelected(selected);
            }
            props.setFilesChanged(false);
            setSaveOpen(false);
          }}
          onClose={(e: any) => {
            e.preventDefault();
            setSaveOpen(false);
          }}
        />
      )}
      <div className="tableTabs">
        <TabBubble
          name={"Budget"}
          selected={props.selected}
          onClick={() => {
            if (props.filesChanged) {
              setSelected("Budget");
              setSaveOpen(true);
            } else {
              props.cancelRequest();
              props.setSelected("Budget");
            }
          }}
          disabled={props.disabled}
        />
        <TabBubble
          name={"FTE"}
          selected={props.selected}
          onClick={() => {
            if (props.filesChanged) {
              setSelected("FTE");
              setSaveOpen(true);
            } else {
              props.cancelRequest();
              props.setSelected("FTE");
            }
          }}
          disabled={props.disabled}
        />
        <TabBubble
          name={"Summary"}
          selected={props.selected}
          onClick={() => {
            if (props.filesChanged) {
              setSelected("Summary");
              setSaveOpen(true);
            } else {
              props.cancelRequest();
              props.setSelected("Summary");
            }
          }}
          disabled={props.disabled}
        />
        <TabBubble
          name={"Original"}
          selected={props.selected}
          onClick={() => {
            if (props.filesChanged) {
              setSelected("Original");
              setSaveOpen(true);
            } else {
              props.cancelRequest();
              props.setSelected("Original");
            }
          }}
          disabled={props.disabled}
        />
      </div>
    </>
  );
};

export default TableTabs;
