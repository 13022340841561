import React from "react";
import "./RibbonPopup.css";

interface RibbonPopupProps {
  message: string;
  error?: boolean;
}

const RibbonPopup: React.FC<RibbonPopupProps> = ({ message, error }) => {
  return (
    <div className="ribbonPopup">
      <div
        className="ribbonPopupBar"
        style={error ? { backgroundColor: "#bd1919" } : {}}></div>
      <div className="ribbonPopupMessage">{message}</div>
    </div>
  );
};

export default RibbonPopup;
