import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import "./App.css";
import MainPage from "./MainPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { loginRequest } from "./authConfig";

function App() {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount && accounts.length === 0) {
      instance.loginRedirect(loginRequest).catch((e) => {});
    }
  }, [instance, accounts]);

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<MainPage />} />
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
