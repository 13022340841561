import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import * as React from "react";

export interface SortableHeaderCell extends Cell, Span {
  type: "sortableHeader";
  text: string;
  sortDirection?: "asc" | "desc";
  isFiltered?: boolean;
}

export class SortableHeaderCellTemplate
  implements CellTemplate<SortableHeaderCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<SortableHeaderCell>
  ): Compatible<SortableHeaderCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  getSortDirectionArrow = (cell: SortableHeaderCell) => {
    if (!cell.sortDirection) return "";

    return cell.sortDirection == "asc" ? "\u2191" : "\u2193";
  };

  getFilter = (cell: SortableHeaderCell) => {
    if (cell.isFiltered) {
      return (
        <>
          <img
            src={"Filter.png"}
            alt={"filter"}
            style={{ height: "16px", width: "16px", marginLeft: "10px" }}
          />
        </>
      );
    } else {
      return "";
    }
  };

  render(
    cell: Compatible<SortableHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<SortableHeaderCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <>
        {cell.text + " " + this.getSortDirectionArrow(cell)}
        {this.getFilter(cell)}
      </>
    );
  }

  isFocusable = (cell: Compatible<SortableHeaderCell>): boolean => false;

  getClassName(
    cell: Compatible<SortableHeaderCell>,
    isInEditMode: boolean
  ): string {
    return cell.className ? cell.className : "";
  }

  getStyle = (cell: Compatible<SortableHeaderCell>): CellStyle => ({});
}
