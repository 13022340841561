import { MouseEventHandler } from "react";
import "./TableTabs.css";
import SaveCountdown from "../SaveCountdown/SaveCountdown";

interface TabBubbleProps {
  name: string;
  selected: string;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  duration?: any;
  reset?: any;
  disabled?: boolean;
}

const TabBubble = (props: TabBubbleProps) => {
  const className = () => {
    if (props.disabled) {
      return "disabledTabBubble";
    } else {
      if (props.selected == props.name) {
        return "selectedTabBubble";
      } else {
        return "tabBubble";
      }
    }
  };

  return (
    <div
      className={className()}
      onClick={props.disabled ? () => {} : props.onClick}>
      {props.name}
      {props.duration ? (
        <SaveCountdown duration={props.duration} reset={props.reset} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TabBubble;
