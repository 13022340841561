import React, { useEffect, useState } from "react";

interface SaveCountdownProps {
  duration: number;
  reset: boolean;
}

const SaveCountdown = (props: SaveCountdownProps) => {
  const [timeLeft, setTimeLeft] = useState(props.duration);

  useEffect(() => {
    setTimeLeft(props.duration);

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [props.duration, props.reset]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.length === 1 ? "0" : ""}${seconds}`;
  };

  return (
    <div style={{ color: "#A7A7A7", marginLeft: "5px" }}>
      ({formatTime(timeLeft)})
    </div>
  );
};

export default SaveCountdown;
