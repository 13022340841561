import { useState } from "react";
import "./ContextMenu.css";
import ContextMenuOption from "./ContextMenuOption";

interface ContextMenuProps {
  x: number;
  y: number;
  multiplication: React.MouseEventHandler<HTMLDivElement>;
  add: React.MouseEventHandler<HTMLDivElement>;
}

const ContextMenu = (props: ContextMenuProps) => {
  return (
    <div className="contextMenu" style={{ top: props.y, left: props.x }}>
      <ContextMenuOption
        label={"Multiplication by factors"}
        img={{ src: "Dismiss.png", alt: "dismiss" }}
        onClick={props.multiplication}
      />
      <ContextMenuOption
        label={"Add static amount"}
        img={{ src: "Add.png", alt: "add" }}
        onClick={props.add}
      />
    </div>
  );
};

export default ContextMenu;
