import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <img className="loadingImg" src="Loading.png" alt="loading" />
    </div>
  );
};

export default Loading;
