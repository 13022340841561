import React, { useEffect, useState, useRef } from "react";
import "./TableName.css";
import SavePopup from "../SavePopup/SavePopup";

interface Option {
  new_codecompany: string;
  new_entityownerid: string;
  new_name: string;
}

interface TableNameProps {
  options: Option[] | undefined;
  selected: Option | undefined;
  setName: (option: Option) => void;
  onSelect: (option: Option) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  save?: any;
  filesChanged: boolean;
  setFilesChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableName = (props: TableNameProps) => {
  const [saveOpen, setSaveOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current && props.options && props.options.length > 0) {
      props.setName(props.options[0]);
      initialRender.current = false;
    }
  }, [props.options, props.setName]);

  return saveOpen ? (
    <SavePopup
      onQuit={(e: any) => {
        e.preventDefault();
        if (selectedOption) {
          props.onSelect(selectedOption);
          props.setName(selectedOption);
        }
        props.setOpen(false);
        props.setFilesChanged(false);
        setSaveOpen(false);
      }}
      onClose={(e: any) => {
        e.preventDefault();
        setSaveOpen(false);
        props.setOpen(false);
      }}
    />
  ) : (
    <div
      className="tableNameSelector"
      onClick={(e: any) => e.stopPropagation()}>
      <div
        className="tableNameHeader"
        onClick={() => props.options && props.setOpen(!props.open)}>
        <span>{props.selected?.new_name || ""}</span>
        {props.options && (
          <img className="tableNameArrow" src="Chevron.png" alt="chevron" />
        )}
      </div>
      {props.open && props.options && (
        <div className="tableNameOptions">
          {props.options.map((option: Option) => (
            <div
              key={option.new_entityownerid}
              className={`tableNameOption ${
                option.new_entityownerid === props.selected?.new_entityownerid
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                if (props.filesChanged) {
                  setSelectedOption(option);
                  setSaveOpen(true);
                } else {
                  props.onSelect(option);
                  props.setName(option);
                  props.setOpen(false);
                }
              }}>
              {option.new_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableName;
