import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useEffect, useRef } from "react";

const useDataverseService = () => {
  const { instance, accounts } = useMsal();
  const cancelTokenSource = (useRef < axios.CancelTokenSource) | (null > null);

  const getAccessToken = async () => {
    const request = {
      scopes: [`${process.env["REACT_APP_DATAVERSE_URL"]}.default`],
      account: accounts[0],
    };
    const response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  };

  const getUsers = async () => {
    const token = await getAccessToken();
    const response = await axios.get(
      `${process.env["REACT_APP_DATAVERSE_URL"]}api/data/v9.0/systemusers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "OData-MaxVersion": "4.0",
          "OData-Version": "4.0",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    return response.data.value;
  };

  const associateRecord = async (
    entitySetName,
    recordId,
    relatedEntitySetName,
    relatedRecordId,
    cancelToken
  ) => {
    try {
      const token = await getAccessToken();

      const payload = {
        ["ownerid@odata.bind"]: `/${relatedEntitySetName}(${relatedRecordId})`,
      };

      const response = await axios.patch(
        `${process.env["REACT_APP_DATAVERSE_URL"]}api/data/v9.2/${entitySetName}(${recordId})`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "OData-MaxVersion": "4.0",
            "OData-Version": "4.0",
            Accept: "application/json",
            "If-Match": "*",
          },
          cancelToken,
        }
      );

      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        //.error("Error associating record in Dataverse", error);
        throw error;
      }
    }
  };

  const putDataBatch = async (requests) => {
    try {
      const token = await getAccessToken();
      const batchBoundary = `batch_${new Date().getTime()}`;
      const changeSetBoundary = `changeset_${new Date().getTime()}`;

      const formatRequestBody = (body) => {
        const formattedBody = {};
        for (const key in body) {
          if (body.hasOwnProperty(key)) {
            const value = body[key];
            if (
              typeof value === "string" &&
              !isNaN(value) &&
              value.trim() !== ""
            ) {
              formattedBody[key] = parseFloat(value);
            } else {
              formattedBody[key] = value;
            }
          }
        }
        return formattedBody;
      };

      let batchBody = `--${batchBoundary}\nContent-Type: multipart/mixed; boundary=${changeSetBoundary}\n\n`;

      requests.forEach((request) => {
        batchBody += `--${changeSetBoundary}\nContent-Type: application/http\nContent-Transfer-Encoding: binary\nContent-ID: ${request.contentId}\n\n`;
        batchBody += `${request.method} ${request.url} HTTP/1.1\nContent-Type: application/json;type=entry\n\n`;
        batchBody += `${JSON.stringify(formatRequestBody(request.body))}\n\n`;
      });

      batchBody += `--${changeSetBoundary}--\n--${batchBoundary}--`;

      const response = await axios.post(
        `${process.env["REACT_APP_DATAVERSE_URL"]}api/data/v9.1/$batch`,
        batchBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": `multipart/mixed; boundary=${batchBoundary}`,
            "OData-MaxVersion": "4.0",
            "OData-Version": "4.0",
          },
        }
      );

      return response.data;
    } catch (error) {
      //console.error("Error in batch update", error);
      throw error;
    }
  };

  const fetchPage = async (url, cancelToken) => {
    try {
      const token = await getAccessToken();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json odata.metadata=minimal",
          Prefer: "odata.maxpagesize=50",
          "OData-Version": "4.0",
        },
        cancelToken,
      });

      return {
        data: response.data,
        nextLink: response.data["@odata.nextLink"],
      };
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        //console.error("Error fetching data from Dataverse", error);
        throw error;
      }
    }
  };

  const fetchData = async (
    entitySetName,
    cancelToken,
    selectedCompanyCode = null
  ) => {
    const columns = [
      "new_521_01",
      "new_521_02",
      "new_521_03",
      "new_521_04",
      "new_521_05",
      "new_521_06",
      "new_521_07",
      "new_521_08",
      "new_521_09",
      "new_521_10",
      "new_521_11",
      "new_521_12",
      "new_524_01",
      "new_524_02",
      "new_524_03",
      "new_524_04",
      "new_524_05",
      "new_524_06",
      "new_524_07",
      "new_524_08",
      "new_524_09",
      "new_524_10",
      "new_524_11",
      "new_524_12",
      "new_525_01",
      "new_525_02",
      "new_525_03",
      "new_525_04",
      "new_525_05",
      "new_525_06",
      "new_525_07",
      "new_525_08",
      "new_525_09",
      "new_525_10",
      "new_525_11",
      "new_525_12",
      "new_5271_01",
      "new_5271_02",
      "new_5271_03",
      "new_5271_04",
      "new_5271_05",
      "new_5271_06",
      "new_5271_07",
      "new_5271_08",
      "new_5271_09",
      "new_5271_10",
      "new_5271_11",
      "new_5271_12",
      "new_5274_01",
      "new_5274_02",
      "new_5274_03",
      "new_5274_04",
      "new_5274_05",
      "new_5274_06",
      "new_5274_07",
      "new_5274_08",
      "new_5274_09",
      "new_5274_10",
      "new_5274_11",
      "new_5274_12",
      "cr864_asisproject",
      entitySetName == "cr864_budgetrecords"
        ? "cr864_budgetrecordid"
        : "cr864_budgetrecordcopyid",
      "cr864_codecompany",
      "cr864_identifier",
      "cr864_note",
      "cr864_phpc",
      "cr864_unifiedcategory",
      "cr864_category",
      "cr864_sum5274",
      entitySetName == "cr864_budgetrecords" ? "cr864_sum5271" : "cr864_sum527",
      "cr864_sum5211",
      "cr864_sum525",
      "cr864_collectioncategoryinbp",

      entitySetName == "cr864_budgetrecords"
        ? [
            "new_submitted",
            "new_sum01",
            "new_sum02",
            "new_sum03",
            "new_sum04",
            "new_sum05",
            "new_sum06",
            "new_sum07",
            "new_sum08",
            "new_sum09",
            "new_sum10",
            "new_sum11",
            "new_sum12",
          ]
        : null,
    ];
    const headColumns = [
      "new_unifiedcategory",
      "new_01",
      "new_02",
      "new_03",
      "new_04",
      "new_05",
      "new_06",
      "new_07",
      "new_08",
      "new_09",
      "new_10",
      "new_11",
      "new_12",
      "new_submitted",
      "new_codecompany",
    ];
    const ownerColumns = [
      "new_codecompany",
      "new_entityownerid",
      "new_name",
      "_ownerid_value",
      "new_codecompany",
      "new_submitted",
    ];
    let filter = "";

    if (selectedCompanyCode) {
      filter =
        entitySetName == "cr864_budgetrecords" ||
        entitySetName == "cr864_budgetrecordcopies"
          ? `&$filter=cr864_codecompany eq '${selectedCompanyCode}'`
          : `&$filter=new_codecompany eq '${selectedCompanyCode}'`;
    }

    const initialUrl = `${
      process.env["REACT_APP_DATAVERSE_URL"]
    }api/data/v9.1/${entitySetName}?$select=${
      entitySetName === "cr864_budgetrecords" ||
      entitySetName === "cr864_budgetrecordcopies"
        ? columns.join(",")
        : entitySetName === "new_entityowners"
        ? ownerColumns.join(",")
        : headColumns.join(",")
    }${
      entitySetName === "cr864_budgetrecords" || "new_headcounts" ? filter : ""
    }`;
    return fetchPage(initialUrl, cancelToken);
  };

  const fetchNextPage = async (nextLink, cancelToken) => {
    return fetchPage(nextLink, cancelToken);
  };

  const fetchDistinctValues = async (
    entitySetName,
    columnName,
    cancelToken,
    selectedCompanyCode,
    selectedTab
  ) => {
    let distinctFilter = "";

    if (selectedCompanyCode) {
      distinctFilter =
        selectedTab == "Summary"
          ? `&$filter=cr864_codecompany eq '${selectedCompanyCode}'`
          : "";
    }

    try {
      const token = await getAccessToken();
      const response = await axios.get(
        `${process.env["REACT_APP_DATAVERSE_URL"]}api/data/v9.1/${entitySetName}?$apply=groupby((${columnName}))${distinctFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          cancelToken,
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        //console.error("Error fetching distinct values from Dataverse", error);
        throw error;
      }
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Operation canceled by the user.");
      }
    };
  }, []);

  return {
    fetchData,
    fetchNextPage,
    putDataBatch,
    getUsers,
    associateRecord,
    fetchDistinctValues,
  };
};

export default useDataverseService;
