import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MainPage.css";
import Header from "./Components/Header/Header";
import TableTabs from "./Components/TableTabs/TableTabs";
import TabBubble from "./Components/TableTabs/TabBubble";
import Table from "./Components/Table/table";
import { useMsal } from "@azure/msal-react";
import useDataverseService from "./dataverseService";
import GroupBy from "./Components/GroupBy/GroupBy";
import Loading from "./Components/Loading/Loading";
import GroupFilter from "./Components/GroupBy/GroupFilter";
import AssignMenu from "./Components/AssignMenu/AssignMenu";
import axios, { CancelTokenSource } from "axios";
import SavePopup from "./Components/SavePopup/SavePopup";
import TableName from "./Components/TableName/TableName";
import SubmitPopup from "./Components/SubmitPopup/SubmitPopup";
import SummaryTable from "./SummaryTable/SummaryTable";
import RibbonPopup from "./Components/RibbonPopup/RibbonPopup";

const MainPage = (props: any) => {
  const [selectedTab, setSelectedTab] = useState("Budget");

  const [data, setData] = useState<any>(null);
  const [filteredItems, setFilteredItems] = useState<any>([data]);
  const [originalData, setOriginalData] = useState<any>();

  const [nextLink, setNextLink] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [groupByVisible, setGroupByVisible] = useState(false);
  const [groupByPosition, setGroupByPosition] = useState({ x: 0, y: 0 });
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);

  const [groupFilterVisible, setGroupFilterVisible] = useState(false);
  const [assignMenuVisible, setAssignMenuVisible] = useState(false);
  const [assignMenuPosition, setAssignMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [entities, setEntities] = useState<any>();
  const [selectedOwner, setSelectedOwner] = useState<any>(null);
  const [tableName, setTableName] = useState<any>();
  const [tableNameOpen, setTableNameOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [ribbonOpen, setRibbonOpen] = useState(false);
  const [ribbonMessage, setRibbonMessage] = useState("");
  const [errorRibbon, setErrorRibbon] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [loggedUserId, setLoggedUserId] = useState<any>();
  const [filesChanged, setFilesChanged] = useState(false);
  const [summaryRows, setSummaryRows] = useState<any>();
  const [summaryRows2, setSummaryRows2] = useState<any>();
  const [resetCountdown, setResetCountdown] = useState(false);

  const { instance, accounts } = useMsal();
  const {
    fetchData,
    putDataBatch,
    fetchNextPage,
    associateRecord,
    fetchDistinctValues,
    getUsers,
  } = useDataverseService();

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [allRecordsSelected, setAllRecordsSelected] = useState<boolean>(false);
  const [selectedRecordsSelected, setSelectedRecordsSelected] =
    useState<boolean>(false);

  const deleteFlagRef = useRef(false);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const tableRef = useRef<any>(null);
  const saveIntervalRef = useRef<any>(null);
  const duration = 5 * 60 * 1000;

  const renames = [
    { oldProp: "identifier", newProp: "Identifikátor" },
    { oldProp: "asisproject", newProp: "ASIS/PROJEKT" },
    { oldProp: "phpc", newProp: "PH/PC" },
    { oldProp: "codecompany", newProp: "CODE COMPANY" },
    { oldProp: "unifiedcategory", newProp: "Zjednotená kategória" },
    { oldProp: "category", newProp: "Kategória" },
    {
      oldProp: "collectioncategoryinbp",
      newProp: "Zberná kategória v BP",
    },
  ];

  useEffect(() => {
    if (cancelTokenSource.current?.cancel) {
      cancelTokenSource.current.cancel("Operation canceled by the user.");
    }
    const loadData = async () => {
      try {
        setLoading(true);
        setDataLoading(true);
        cancelTokenSource.current = axios.CancelToken.source();

        const users = await getUsers();

        const currentUser = users.find(
          (u: any) => u.domainname == instance.getActiveAccount()?.username
        );
        setLoggedUserId(currentUser.systemuserid);

        const owners: any = await fetchData("new_entityowners");

        const currentOwners = owners.data.value.filter(
          (o: any) => o._ownerid_value == currentUser.systemuserid
        );

        const distinctValues = await fetchDistinctValues(
          "cr864_budgetrecords",
          "cr864_codecompany",
          cancelTokenSource.current.token
        );

        const distinctFtes = await fetchDistinctValues(
          "new_headcounts",
          "new_codecompany",
          cancelTokenSource.current.token
        );

        const combinedDistinct = new Set([
          ...distinctValues.value.map((d: any) => d.cr864_codecompany),
          ...distinctFtes.value.map((d: any) => d.new_codecompany),
        ]);

        const disctinctOwners = owners.data.value.filter((o: any) =>
          combinedDistinct.has(o.new_codecompany)
        );

        const mergedEntities = mergeUniqueEntities(
          currentOwners,
          disctinctOwners
        );

        const selectedCompanyCode = tableName
          ? tableName.new_codecompany
          : mergedEntities[0]?.new_codecompany;

        if (currentOwners && disctinctOwners) {
          setEntities(mergedEntities);
          if (!tableName) setTableName(mergedEntities[0]);
        } else if (!currentOwners) {
          setEntities(disctinctOwners);
          if (!tableName) setTableName(disctinctOwners[0]);
        }

        const tempData: any = await fetchData(
          selectedTab === "Budget"
            ? "cr864_budgetrecords"
            : selectedTab === "FTE"
            ? "new_headcounts"
            : selectedTab === "Summary"
            ? "cr864_budgetrecords"
            : selectedTab === "Original"
            ? "cr864_budgetrecordcopies"
            : "",
          cancelTokenSource.current.token,
          selectedCompanyCode
        );
        if (!tempData || !tempData.data) {
          return;
        }

        const tempHeadData: any = await fetchData(
          "new_headcounts",
          cancelTokenSource.current.token,
          selectedCompanyCode
        );

        setOriginalData({
          ...tempData,
          value: tempData.data.value,
          headCount: tempHeadData.data.value,
        });

        const filteredData = {
          ...tempData,
          value: tempData.data.value.map((item: any) => filterNewPrefix(item)),
          headCount: tempHeadData.data.value.map((item: any) =>
            filterNewPrefix(item)
          ),
        };

        const filteredDataNoPref = {
          ...filteredData,
          value: filteredData.value.map((item: any) => removePrefix(item)),
          headCount: filteredData.headCount.map((item: any) =>
            removePrefix(item)
          ),
        };

        const finalData = {
          ...filteredDataNoPref,
          value: filteredDataNoPref.value.map((item: any) =>
            renameProps(item, renames)
          ),
          headCount: filteredDataNoPref.headCount.map((item: any) =>
            renameProps(item, renames)
          ),
        };

        if (selectedTab == "Summary") {
          const summaryRows = await fetchDistinctValues(
            "cr864_budgetrecords",
            "cr864_asisproject",
            cancelTokenSource.current.token,
            selectedCompanyCode,
            selectedTab
          );
          const summaryRows2 = await fetchDistinctValues(
            "cr864_budgetrecords",
            "cr864_unifiedcategory",
            cancelTokenSource.current.token,
            selectedCompanyCode,
            selectedTab
          );
          setSummaryRows(summaryRows);
          setSummaryRows2(summaryRows2);
        }

        setNextLink(tempData.nextLink);
        setData(finalData);
        setFilteredItems(finalData);
        if (tempData.nextLink == null) {
          setDataLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
        }
      } finally {
        setLoading(false);
      }
    };

    loadData();

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Operation canceled by the user.");
      }
    };
  }, [selectedTab, tableName]);

  const mergeUniqueEntities = (arr1: any, arr2: any) => {
    const combined = [...arr1, ...arr2];
    const uniqueEntities = new Map();

    combined.forEach((item) => {
      uniqueEntities.set(item.new_entityownerid, item);
    });

    return Array.from(uniqueEntities.values());
  };

  useEffect(() => {
    const fetchDataForOwner = async () => {
      if (!selectedOwner) return;

      try {
        setDataLoading(true);
        cancelTokenSource.current = axios.CancelToken.source();

        const selectedCompanyCode = selectedOwner.new_codecompany;

        const tempData: any = await fetchData(
          selectedTab === "Budget"
            ? "cr864_budgetrecords"
            : selectedTab === "FTE"
            ? "new_headcounts"
            : selectedTab === "Summary"
            ? "cr864_budgetrecords"
            : selectedTab === "Original"
            ? "cr864_budgetrecordcopies"
            : "",
          cancelTokenSource.current.token,
          selectedCompanyCode
        );

        if (!tempData || !tempData.data) {
          return;
        }

        setOriginalData({
          ...tempData,
          value: tempData.data.value,
        });

        const filteredData = {
          ...tempData,
          value: tempData.data.value.map((item: any) => filterNewPrefix(item)),
        };

        const filteredDataNoPref = {
          ...filteredData,
          value: filteredData.value.map((item: any) => removePrefix(item)),
        };

        const finalData = {
          ...filteredDataNoPref,
          value: filteredDataNoPref.value.map((item: any) =>
            renameProps(item, renames)
          ),
        };

        setNextLink(tempData.nextLink);
        setData(finalData);
        setFilteredItems(finalData);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
        }
      } finally {
        setDataLoading(false);
      }
    };

    fetchDataForOwner();
  }, [selectedOwner, selectedTab]);

  const handleOwnerChange = (newOwner: any) => {
    handleCancelRequest();
    setSelectedOwner(newOwner);
  };

  const handleCancelRequest = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation canceled by the user.");
    }
  };

  const getNextPage = async () => {
    if (!nextLink || nextLink == undefined) {
      setDataLoading(false);
      return;
    }
    try {
      cancelTokenSource.current = axios.CancelToken.source();
      const tempData: any = await fetchNextPage(
        nextLink,
        cancelTokenSource.current.token
      );

      if (!tempData || !tempData.data) {
        return;
      }

      setOriginalData((prevData: any) => ({
        ...prevData,
        value: [...(prevData.value || []), ...tempData.data.value],
      }));

      const filteredData = {
        ...tempData,
        value: tempData.data.value.map((item: any) => filterNewPrefix(item)),
      };

      const filteredDataNoPref = {
        ...filteredData,
        value: filteredData.value.map((item: any) => removePrefix(item)),
      };

      const finalData = {
        ...filteredDataNoPref,
        value: filteredDataNoPref.value.map((item: any) =>
          renameProps(item, renames)
        ),
      };

      setNextLink(tempData.nextLink);
      setData((prevData: any) => ({
        ...prevData,
        value: [...prevData.value, ...finalData.value],
      }));

      setFilteredItems((prevFilteredItems: any) => ({
        ...prevFilteredItems,
        value: [...prevFilteredItems.value, ...finalData.value],
      }));
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
      }
    }
  };

  useEffect(() => {
    if (nextLink) {
      getNextPage();
    } else {
      setDataLoading(false);
    }
  }, [nextLink]);

  const filterNewPrefix = (obj: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (key.startsWith("cr864_")) {
        newObj[key] = obj[key];
      } else if (key.startsWith("new_")) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };

  const removePrefix = (obj: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (key.startsWith("cr864__")) {
        newObj[key.slice(7)] = obj[key];
      } else if (key.startsWith("cr864_")) {
        newObj[key.slice(6)] = obj[key];
      } else if (key.startsWith("new_")) {
        newObj[key.slice(4)] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };

  const renameProps = (
    obj: any,
    mappings: { oldProp: string; newProp: string }[]
  ) => {
    mappings.forEach(({ oldProp, newProp }) => {
      if (obj.hasOwnProperty(oldProp)) {
        obj[newProp] = obj[oldProp];
        delete obj[oldProp];
      }
    });
    return obj;
  };

  const renderTable = useCallback(() => {
    if (!data) return null;
    return (
      <Table
        ref={tableRef}
        originalData={originalData}
        data={filteredItems}
        selectedTab={selectedTab}
        dataLoading={dataLoading}
        selected={selectedRows}
        onSelect={setSelectedRows}
        fileChange={setFilesChanged}
        submitted={tableName?.new_submitted}
      />
    );
  }, [data, selectedTab, filteredItems, selectedFilters]);

  const handleGroupByVisible = (event: any) => {
    setGroupByPosition({ x: event.pageX, y: event.pageY });
    setGroupByVisible(!groupByVisible);
  };

  const handleAssignMenuVisible = (event: any) => {
    setAssignMenuPosition({ x: event.pageX, y: event.pageY });
    setAssignMenuVisible(!assignMenuVisible);
  };

  const handleSaveChanges = async () => {
    if (!filteredItems || !filteredItems.value || !filteredItems.headCount) {
      return;
    }

    const updates = filteredItems.value.reduce((acc: any, row: any) => {
      const originalRow = originalData.value.find((r: any) =>
        selectedTab == "Budget"
          ? r.cr864_budgetrecordid === row.budgetrecordid
          : r.new_headcountid === row.headcountid
      );
      if (!originalRow) {
        return acc;
      }

      const properties: any = {};

      Object.keys(row).forEach((key) => {
        let prefixedKey = key;

        const keyMappings: { [key: string]: string } = {
          Identifikátor: "cr864_identifier",
          submitted: "new_submitted",
          "ASIS/PROJEKT": "cr864_asisproject",
          "PH/PC": "cr864_phpc",
          "CODE COMPANY": "cr864_codecompany",
          "Zjednotená kategória": "cr864_unifiedcategory",
          Kategória: "cr864_category",
          "Zberná kategória v BP": "cr864_collectioncategoryinbp",
        };

        if (selectedTab === "FTE" && key === "Identifikátor") {
          prefixedKey = "new_name";
        } else if (selectedTab === "FTE") {
          prefixedKey = `new_${key}`;
        } else if (keyMappings[key]) {
          prefixedKey = keyMappings[key];
        } else if (key.startsWith("5")) {
          prefixedKey = `new_${key}`;
        } else if (!key.startsWith("cr864_") && !key.startsWith("new_")) {
          if (key.match(/^\d/)) {
            prefixedKey = `cr864__${key}`;
          } else {
            prefixedKey = `cr864_${key}`;
          }
        }

        if (originalRow.hasOwnProperty(prefixedKey)) {
          const newValue = row[key];
          const originalValue = originalRow[prefixedKey];

          if (
            newValue !== originalValue &&
            newValue !== null &&
            newValue !== undefined
          ) {
            properties[prefixedKey] = newValue;
          }
        }
      });

      if (Object.keys(properties).length > 0) {
        const existingUpdateIndex = acc.findIndex(
          (update: any) =>
            update.rowId === (row.budgetrecordid || row.headcountid)
        );

        if (existingUpdateIndex !== -1) {
          acc[existingUpdateIndex].properties = {
            ...acc[existingUpdateIndex].properties,
            ...properties,
          };
        } else {
          acc.push({
            rowId: row.budgetrecordid || row.headcountid,
            properties,
            entitySetName: row.budgetrecordid
              ? "cr864_budgetrecords"
              : "new_headcounts",
          });
        }
      }

      return acc;
    }, []);

    filteredItems.headCount.forEach((row: any) => {
      const originalRow = originalData.headCount.find(
        (r: any) => r.new_headcountid === row.headcountid
      );
      if (!originalRow) {
        return;
      }

      const properties: any = {};

      Object.keys(row).forEach((key) => {
        let prefixedKey = key;

        const keyMappings: { [key: string]: string } = {
          Identifikátor: "cr864_identifier",
          submitted: "new_submitted",
          "ASIS/PROJEKT": "cr864_asisproject",
          "PH/PC": "cr864_phpc",
          "CODE COMPANY": "cr864_codecompany",
          "Zjednotená kategória": "cr864_unifiedcategory",
          Kategória: "cr864_category",
          "Zberná kategória v BP": "cr864_collectioncategoryinbp",
        };

        if (selectedTab === "FTE" && key === "Identifikátor") {
          prefixedKey = "new_name";
        } else if (selectedTab === "FTE") {
          prefixedKey = `new_${key}`;
        } else if (keyMappings[key]) {
          prefixedKey = keyMappings[key];
        } else if (!key.startsWith("cr864_") && !key.startsWith("new_")) {
          if (key.match(/^\d/)) {
            prefixedKey = `cr864__${key}`;
          } else {
            prefixedKey = `cr864_${key}`;
          }
        }

        if (originalRow.hasOwnProperty(prefixedKey)) {
          const newValue = row[key];
          const originalValue = originalRow[prefixedKey];

          if (
            newValue !== originalValue &&
            newValue !== null &&
            newValue !== undefined
          ) {
            properties[prefixedKey] = newValue;
          }
        }
      });

      if (Object.keys(properties).length > 0) {
        const existingUpdateIndex = updates.findIndex(
          (update: any) => update.rowId === row.headcountid
        );

        if (existingUpdateIndex !== -1) {
          updates[existingUpdateIndex].properties = {
            ...updates[existingUpdateIndex].properties,
            ...properties,
          };
        } else {
          updates.push({
            rowId: row.headcountid,
            properties,
            entitySetName: "new_headcounts",
          });
        }
      }
    });

    updates.forEach((update: any) => {
      const originalRow = originalData.value.find(
        (r: any) =>
          r.cr864_budgetrecordid === update.rowId ||
          r.new_headcountid === update.rowId
      );

      if (originalRow) {
        Object.keys(update.properties).forEach((key) => {
          if (originalRow[key] === update.properties[key]) {
            delete update.properties[key];
          }
        });
      }
    });

    const filteredUpdates = updates.filter((update: any) => {
      const keys = Object.keys(update.properties);
      return keys.length != 0;
    });

    const batchRequests = filteredUpdates.map((update: any, index: number) => {
      const { rowId, properties, entitySetName } = update;
      return {
        method: "PATCH",
        url: `${process.env["REACT_APP_DATAVERSE_URL"]}api/data/v9.1/${entitySetName}(${rowId})`,
        body: properties,
        contentId: `${index + 1}`,
      };
    });

    try {
      await putDataBatch(batchRequests);
      setFilesChanged(false);
      setRibbonMessage("Your data has been saved successfully.");
      setRibbonOpen(true);
      setTimeout(() => {
        setRibbonOpen(false);
      }, 3000);
    } catch (error) {}
  };

  useEffect(() => {
    if (data) {
      const uniqueFilter = new Map();

      data.value.forEach((item: any) => {
        const noPref = removePrefix(item);
        Object.keys(noPref).forEach((key) => {
          const match = key.match(/^(\d+)_/);
          if (match) {
            let filterKey = match[1];
            let originalKey = match[1];
            if (filterKey.length === 4) {
              filterKey = `${filterKey.substring(0, 3)}.${filterKey.substring(
                3,
                4
              )}`;
            }
            uniqueFilter.set(filterKey, originalKey);
          }
        });
      });

      const filterArray = Array.from(uniqueFilter).map(
        ([filter, original]) => ({
          label: filter,
          value: original,
        })
      );

      setFilterOptions(filterArray);
    }
  }, [data]);

  const handleGroupByApply = () => {
    const alwaysIncludeColumns =
      selectedTab === "Budget" || selectedTab === "Original"
        ? [
            "Identifikátor",
            "ASIS/PROJEKT",
            "PH/PC",
            "CODE COMPANY",
            "Zjednotená kategória",
            "Kategória",
            "Zberná kategória v BP",
          ]
        : ["Identifikátor", "entity", "Zjednotená kategória"];
    if (selectedFilters.length === 0) {
      setFilteredItems(data);
      return;
    }
    if (data) {
      const filteredArray = data.value.map((item: any) => {
        const noPref = removePrefix(item);

        const filteredItem = selectedFilters.reduce((acc: any, filter: any) => {
          const filterKeys = Object.keys(noPref)
            .filter((key) => {
              const regex = new RegExp(`^${filter.value}(_|$)`);
              return regex.test(key);
            })
            .reduce((filteredAcc: any, key) => {
              filteredAcc[key] = noPref[key];
              return filteredAcc;
            }, {});
          return { ...acc, ...filterKeys };
        }, {});

        alwaysIncludeColumns.forEach((column) => {
          if (noPref.hasOwnProperty(column)) {
            filteredItem[column] = noPref[column];
          }
        });

        return filteredItem;
      });

      setGroupFilterVisible(true);
      setGroupByVisible(false);
      setFilteredItems({ value: filteredArray });
    }
  };

  const handleGroupByClear = () => {
    setSelectedFilters([]);
    setFilteredItems(data);
  };

  useEffect(() => {
    if (selectedFilters.length === 0) setGroupFilterVisible(false);
  }, [selectedFilters]);

  const handleGroupByFilterSelect = (filter: any) => {
    setSelectedFilters(filter);
  };

  const handleGroupByFilterDelete = (filterValue: any) => {
    setSelectedFilters((prevFilters: any) => {
      const newFilters = prevFilters.filter(
        (f: any) => f.value !== filterValue
      );
      return newFilters;
    });
    deleteFlagRef.current = true;
  };

  useEffect(() => {
    if (deleteFlagRef.current) {
      handleGroupByApply();
      deleteFlagRef.current = false;
    }
  }, [selectedFilters]);

  const handleReturn = async (ownerId: any) => {
    const users = await getUsers();
    const owner = users.filter((u: any) => u.systemuserid == ownerId);
    if (owner[0]) {
      handleAssignUser(owner[0], false, true);
    }
  };

  const handleAssignUser = async (
    user: any,
    selectedRecordsSelected: boolean,
    allRecordsSelected: boolean
  ) => {
    const systemUserId = user.systemuserid;

    const selectedRowsArray: any[] = [];

    const assignedItemIds: string[] = [];

    if (selectedRecordsSelected) {
      originalData.value.forEach((item: any) => {
        const isSelected = selectedRows[0].rows.some((selectedRow: any) => {
          return (
            selectedRow.id === item.cr864_budgetrecordid ||
            selectedRow.id === item.new_headcountid
          );
        });

        if (isSelected) {
          selectedRowsArray.push(item);
          item._ownerid_value = systemUserId;
          assignedItemIds.push(
            selectedTab === "Budget"
              ? item.cr864_budgetrecordid
              : item.new_headcountid
          );
        }
      });
    } else if (allRecordsSelected) {
      originalData.value.forEach((item: any) => {
        selectedRowsArray.push(item);
        item._ownerid_value = systemUserId;
        assignedItemIds.push(
          selectedTab === "Budget" ? item.budgetrecordid : item.headcountid
        );
      });
    }

    var error = false;

    for (var row of selectedRowsArray) {
      await associateRecord(
        selectedTab === "Budget"
          ? "cr864_budgetrecords"
          : selectedTab === "FTE"
          ? "new_headcounts"
          : "",
        selectedTab === "Budget"
          ? row.cr864_budgetrecordid
          : row.new_headcountid,
        "systemusers",
        systemUserId
      ).catch((e) => {
        setErrorRibbon(true);
        setErrorMessage(
          `"Prosim, o pridelenie pristupu do HR Budgeting app pre ${user.internalemailaddress}`
        );
        error = true;
      });
    }

    if (!error) {
      const updatedFilteredItems = filteredItems.value.filter((item: any) => {
        const itemId =
          selectedTab === "Budget" ? item.budgetrecordid : item.headcountid;
        return !assignedItemIds.includes(itemId);
      });

      setFilteredItems({ value: updatedFilteredItems });

      setAllRecordsSelected(false);
      setSelectedRecordsSelected(false);

      setRibbonMessage("Your assignment was completed successfully.");
      setRibbonOpen(true);
    }

    setTimeout(() => {
      setRibbonOpen(false);
      setErrorRibbon(false);
      setErrorMessage("");
    }, 5000);
  };

  useEffect(() => {
    if (filteredItems.value && filteredItems.value.length > 0) {
      const hasSubmitted = filteredItems.value.some(
        (row: any) => row.submitted === "true"
      );
      if (hasSubmitted) {
        handleSaveChanges();
      }
    }
  }, [filteredItems]);

  const handleSubmit = async () => {
    const updatedFilteredItems = {
      ...filteredItems,
      value: filteredItems.value.map((row: any) => {
        const originalRow = originalData.value.find(
          (r: any) => r.new_entityid === row.entityid
        );

        if (!originalRow) {
          return row;
        }

        return {
          ...row,
          submitted: "true",
        };
      }),
      headCount: filteredItems.headCount.map((row: any) => {
        const originalRow = originalData.headCount.find(
          (r: any) => r.new_headcountid === row.headcountid
        );

        if (!originalRow) {
          return row;
        }

        return {
          ...row,
          submitted: "true",
        };
      }),
    };

    setFilteredItems(updatedFilteredItems);
  };

  useEffect(() => {
    saveIntervalRef.current = setInterval(() => {
      handleSaveChanges();
      setResetCountdown(true);
    }, duration);

    return () => {
      if (saveIntervalRef.current) {
        clearInterval(saveIntervalRef.current);
      }
    };
  }, [duration, handleSaveChanges]);

  useEffect(() => {
    if (resetCountdown) {
      setResetCountdown(false);
    }
  }, [resetCountdown]);

  const summaryCollumns = [
    "",
    "Januar",
    "Februar",
    "Marec",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "August",
    "September",
    "Oktober",
    "November",
    "December",
  ];
  const summaryCollumns2 = [
    "",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const handleAllRecordsChange = () => {
    setAllRecordsSelected(!allRecordsSelected);
    setSelectedRecordsSelected(false);
  };

  const handleSelectedRecordsChange = () => {
    setAllRecordsSelected(false);
    setSelectedRecordsSelected(!selectedRecordsSelected);
  };

  return (
    <div
      className="mainPage"
      onClick={(e: any) => {
        setGroupByVisible(false);
        setAssignMenuVisible(false);
        setAllRecordsSelected(false);
        setSelectedRecordsSelected(false);
        setTableNameOpen(false);
        e.preventDefault();
      }}>
      {ribbonOpen && <RibbonPopup message={ribbonMessage} />}
      {errorRibbon && <RibbonPopup message={errorMessage} error />}
      <Header name={instance.getActiveAccount()?.name} />
      <div className="title">
        <div style={{ display: "flex", flexDirection: "row" }}>
          Budgeting for 2025 - {"  "}
          <TableName
            options={entities}
            open={tableNameOpen}
            setOpen={setTableNameOpen}
            setName={setTableName}
            onSelect={handleOwnerChange}
            selected={tableName}
            filesChanged={filesChanged}
            save={setSaveOpen}
            setFilesChanged={setFilesChanged}
          />
        </div>
        <div style={{ height: "30px", marginLeft: "20px" }}>
          {selectedTab == "Budget" &&
            tableName &&
            tableName._ownerid_value == loggedUserId &&
            !tableName.new_submitted && (
              <TabBubble
                name="Submit"
                selected="Submit"
                onClick={() => setSubmitPopupOpen(true)}
              />
            )}
        </div>

        <div className="headerButtons">
          {(selectedTab == "Budget" || selectedTab == "FTE") && (
            <>
              {tableName && tableName._ownerid_value != loggedUserId && (
                <TabBubble
                  name="Return"
                  selected=""
                  onClick={(e: any) => {
                    handleReturn(tableName._ownerid_value);
                    e.stopPropagation();
                  }}
                />
              )}
              {tableName &&
                tableName._ownerid_value == loggedUserId &&
                !tableName.new_submitted && (
                  <TabBubble
                    name="Assign"
                    selected=""
                    onClick={(e: any) => {
                      handleAssignMenuVisible(e);
                      e.stopPropagation();
                    }}
                  />
                )}
              {tableName && !tableName.new_submitted && (
                <TabBubble
                  name="Save"
                  selected=""
                  onClick={() => handleSaveChanges()}
                  duration={duration}
                  reset={resetCountdown}
                />
              )}
            </>
          )}
          {submitPopupOpen && (
            <SubmitPopup
              onClose={() => setSubmitPopupOpen(false)}
              onSubmit={() => {
                handleSubmit();
                setSubmitPopupOpen(false);
              }}
            />
          )}
          {assignMenuVisible && (
            <AssignMenu
              x={assignMenuPosition.x}
              y={assignMenuPosition.y}
              table={tableName}
              onDismiss={setAssignMenuVisible}
              onAssign={handleAssignUser}
              allRecordsSelected={allRecordsSelected}
              selectedRecordsSelected={selectedRecordsSelected}
              handleAllRecords={handleAllRecordsChange}
              handleSelectedRecords={handleSelectedRecordsChange}
            />
          )}
          {saveOpen && (
            <SavePopup
              onClose={(e: any) => {
                e.preventDefault();
                setSaveOpen(!saveOpen);
              }}
            />
          )}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="contentContainer">
          <TableTabs
            filesChanged={filesChanged}
            setFilesChanged={setFilesChanged}
            selected={selectedTab}
            setSelected={setSelectedTab}
            cancelRequest={handleCancelRequest}
            disabled={dataLoading}
          />

          <div className="groupByContainer">
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              {groupFilterVisible && (
                <GroupFilter
                  filters={selectedFilters}
                  onDelete={handleGroupByFilterDelete}
                  apply={() => handleGroupByApply()}
                />
              )}
            </div>
            {(selectedTab === "Budget" || selectedTab === "Original") && (
              <img
                style={{ height: "20px", width: "20px" }}
                onClick={(e) => {
                  handleGroupByVisible(e);
                  e.stopPropagation();
                }}
                src="Filter.png"
                alt="filter"
              />
            )}
            {groupByVisible && (
              <GroupBy
                x={groupByPosition.x}
                y={groupByPosition.y}
                options={filterOptions}
                selectedFilters={selectedFilters}
                selectedFilter={handleGroupByFilterSelect}
                onDismiss={() => setGroupByVisible(false)}
                onApply={() => handleGroupByApply()}
                onClear={() => handleGroupByClear()}
              />
            )}
          </div>
          {filteredItems.value &&
            filteredItems.value.length > 0 &&
            selectedTab != "Summary" && (
              <div className="tableBackground">{renderTable()}</div>
            )}
          {selectedTab === "Summary" ? (
            <>
              <div style={{ height: "40%" }} className="tableBackground">
                <SummaryTable
                  ref={tableRef}
                  selected={selectedRows}
                  originalData={originalData}
                  data={filteredItems}
                  selectedTab={selectedTab}
                  dataLoading={dataLoading}
                  onSelect={setSelectedRows}
                  columns={summaryCollumns}
                  rows={summaryRows}
                  tableName={tableName}
                />
              </div>
              <div style={{ height: "40%" }} className="tableBackground">
                <SummaryTable
                  ref={tableRef}
                  selected={selectedRows}
                  originalData={originalData}
                  data={filteredItems}
                  selectedTab={selectedTab}
                  dataLoading={dataLoading}
                  onSelect={setSelectedRows}
                  columns={summaryCollumns2}
                  rows={summaryRows2}
                  tableName={tableName}
                />
              </div>
            </>
          ) : undefined}
        </div>
      )}
    </div>
  );
};

export default MainPage;
