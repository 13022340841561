import { Column, Id, ReactGrid, Row } from "@silevis/reactgrid";
import React, {
  MouseEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { SortableHeaderCellTemplate } from "../CellTemplates/SortableHeaderCell";
import { SummaryCellTemplate } from "../CellTemplates/SummaryCell";
import ContextMenu from "../ContextMenu/ContextMenu";
import FilterMenu from "../FilterMenu/FilterMenu";
import SortMenu from "../SortMenu/SortMenu";
import "./table.css";
import AddAmount from "../AddAmount/AddAmount";

type CellLocation = {
  rowId: Id;
  columnId: Id;
};

type Position = {
  x: number;
  y: number;
};

const defaultFilterOptions = [
  { value: "equals", label: "Equals" },
  { value: "contains", label: "Contains" },
  { value: "morethan", label: "More than" },
  { value: "lessthan", label: "Less than" },
];

interface TableProps {
  originalData: any;
  data: any;
  selected: any;
  selectedTab: string;
  dataLoading: boolean;
  onSelect: (selectedRows: any[]) => void;
  fileChange?: any;
  submitted: boolean;
}

const Table = forwardRef((props: TableProps, ref) => {
  const [dummyData, setDummydata] = React.useState<any[]>(props.data.value);
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [selectedColumn, setSelectedColumn] = React.useState<any>();
  const [rows, setRows] = React.useState<Row[]>([]);
  const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
  const [menuPosition, setMenuPosition] = React.useState<Position>({
    x: 0,
    y: 0,
  });
  const [selectedCell, setSelectedCell] = React.useState<
    CellLocation | undefined
  >();
  const [sortMenuVisible, setSortMenuVisible] = React.useState<boolean>(false);
  const [sortMenuPosition, setSortMenuPosition] = React.useState<Position>({
    x: 0,
    y: 0,
  });
  const [filterValue, setFilterValue] = React.useState<number | string>();
  const [filterOption, setFilterOption] = React.useState({
    value: "equals",
    label: "Equals",
  });
  const [filterOptions, setFilterOptions] =
    React.useState(defaultFilterOptions);
  const [selectedRange, setSelectedRange] = React.useState<any[]>([]);
  const [filterMenuVisible, setFilterMenuVisible] = React.useState(false);
  const [addAmountVisible, setAddAmountVisible] = React.useState(false);
  const [addAmountValue, setAddAmountValue] = React.useState<
    number | undefined
  >(undefined);
  const [addAmountHeader, setAddAmountHeader] = React.useState<
    string | undefined
  >();
  const [addAmountType, setAddAmountType] = React.useState<
    "multiply" | "add" | undefined
  >();
  const rowHeight = 35;

  useEffect(() => {
    props.onSelect(props.selected);
  }, [props.selected]);

  const columnWidth = (id: any) => {
    let width = 100;
    switch (id) {
      case " ":
        width = 40;
        return width;
      case "Identifikátor":
        width = 155;
        return width;
      case "ASIS/PROJEKT":
        width = 95;
        return width;
      case "PH/PC":
        width = 50;
        return width;
      case "CODE COMPANY":
        width = 115;
        return width;
      case "Zjednotená kategória":
        width = 140;
        return width;
      case "Kategória":
        width = 70;
        return width;
      case "Zberná kategória v BP":
        width = 145;
        return width;
      default:
        return width;
    }
  };

  const getColumns = () => {
    const uniqueColumns = new Set<string>();
    props.data.value.forEach((item: any) => {
      Object.keys(item).forEach((key) => {
        if (
          (props.selectedTab === "Budget" && key !== "budgetrecordid") ||
          (props.selectedTab === "FTE" &&
            key !== "headcountid" &&
            key != "submitted") ||
          (props.selectedTab === "Original" &&
            key !== "budgetrecordcopyid" &&
            key !== "note") ||
          (props.selectedTab !== "Budget" &&
            props.selectedTab !== "FTE" &&
            props.selectedTab !== "Original")
        ) {
          uniqueColumns.add(key);
        }
      });
    });

    let columnsArray: Column[] = [];

    const priorityColumns =
      props.selectedTab === "Budget" || props.selectedTab === "Original"
        ? [
            " ",
            "Identifikátor",
            "ASIS/PROJEKT",
            "PH/PC",
            "CODE COMPANY",
            "Zjednotená kategória",
            "Kategória",
            "Zberná kategória v BP",
          ]
        : props.selectedTab === "FTE"
        ? ["CODE COMPANY", "Zjednotená kategória"]
        : [];

    const defaultColumnWidth = 90;

    priorityColumns.forEach((columnId) => {
      if (uniqueColumns.has(columnId)) {
        columnsArray.push({
          columnId,
          width: columnWidth(columnId),
          resizable: true,
        });
        uniqueColumns.delete(columnId);
      } else {
        columnsArray.push({
          columnId,
          width: columnWidth(columnId),
          resizable: true,
        });
      }
    });

    uniqueColumns.forEach((key) =>
      columnsArray.push({
        columnId: key,
        width: defaultColumnWidth,
        resizable: true,
      })
    );

    columnsArray = [
      ...columnsArray.slice(0, priorityColumns.length),
      ...columnsArray.slice(priorityColumns.length).sort((a: any, b: any) => {
        let aText = a.columnId.toLowerCase();
        let bText = b.columnId.toLowerCase();
        return aText < bText ? -1 : aText > bText ? 1 : 0;
      }),
    ];

    setColumns(columnsArray);
  };

  const getHeaderRow = React.useCallback((): Row => {
    const rowNames: any[] = [];
    columns.forEach((c: any) => {
      let headerText = c.columnId;

      if (headerText.startsWith("cr864__")) {
        headerText = headerText.replace(/^cr864__/, "");
      } else if (headerText.startsWith("cr864_")) {
        headerText = headerText.replace(/^cr864_/, "");
      } else if (headerText.startsWith("new_")) {
        headerText = headerText.replace(/^new_/, "");
      }

      const match = headerText.match(/^(\d{3})(\d{1})_(\d{2})$/);
      if (match) {
        headerText = `${match[1]}.${match[2]}_${match[3]}`;
      }

      if (headerText.startsWith("sum")) {
        headerText = headerText.replace(/^sum/, "spolu_");
      }

      rowNames.push({
        type: "sortableHeader",
        text: headerText,
        groupId: c.columnId,
        isFiltered: false,
      });
    });
    return {
      rowId: "header",
      height: rowHeight,
      cells: rowNames,
    };
  }, [columns]);

  const calculateSummaries = (rows: any) => {
    const summaries: any = {};

    rows.forEach((row: any) => {
      row.cells.forEach((cell: any) => {
        if (cell.type === "number") {
          if (!summaries[cell.groupId]) {
            summaries[cell.groupId] = 0;
          }
          summaries[cell.groupId] += cell.value;
        }
      });
    });

    Object.keys(summaries).forEach((key) => {
      summaries[key] = parseFloat(summaries[key].toFixed(2)).toLocaleString();
    });

    return summaries;
  };

  const createSummaryRow = (summaries: any, columns: any) => {
    const cells = columns.map((column: any) => {
      const summaryValue = summaries[column.columnId];
      return {
        type: "summary",
        value: summaryValue || "",
        text:
          column.columnId === "CODE COMPANY"
            ? "Summary"
            : summaryValue
            ? summaryValue.toString()
            : "",
        groupId: column.columnId,
      };
    });
    return {
      rowId: "summary",
      height: rowHeight,
      cells: cells,
    };
  };

  const getRows = (people: any[]): any[] => {
    const dataRows = people.map((person, idx: any) => {
      let cells: any[] = columns.map((column: any) => {
        const value = person[column.columnId];
        const isReadOnly = () => {
          if (props.submitted) {
            return true;
          } else if (
            props.selectedTab === "Budget" &&
            (column.columnId.startsWith("524") ||
              column.columnId.startsWith("527") ||
              column.columnId == "submitted")
          ) {
            return true;
          } else if (props.selectedTab === "Original") {
            return true;
          } else {
            return false;
          }
        };
        const isSticky =
          ((props.selectedTab === "Budget" ||
            props.selectedTab === "Original") &&
            [
              " ",
              "Identifikátor",
              "ASIS/PROJEKT",
              "PH/PC",
              "CODE COMPANY",
              "Zjednotená kategória",
              "Kategória",
              "Zberná kategória v BP",
            ].includes(column.columnId)) ||
          (props.selectedTab === "FTE" &&
            ["Identifikátor", "entity", "Zjednotená kategória"].includes(
              column.columnId
            ));

        if (typeof value === "number") {
          return {
            type: "number",
            value: value,
            groupId: column.columnId,
            readOnly: isSticky || isReadOnly(),
          };
        } else if (typeof value === "string") {
          return {
            type: "text",
            text: value,
            groupId: column.columnId,
            readOnly: isSticky || isReadOnly(),
          };
        } else {
          return {
            type: "text",
            text: "",
            groupId: column.columnId,
            readOnly: isSticky || isReadOnly(),
          };
        }
      });
      return {
        rowId: idx,
        height: rowHeight,
        cells: cells,
        id:
          props.selectedTab === "Budget"
            ? person.budgetrecordid
            : person.headcountid,
      };
    });

    if (props.selectedTab === "FTE") {
      const summaries = calculateSummaries(dataRows);
      const summaryRow = createSummaryRow(summaries, columns);

      return [getHeaderRow(), ...dataRows, summaryRow];
    } else {
      return [getHeaderRow(), ...dataRows];
    }
  };

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns: any) => {
      const columnIndex = prevColumns.findIndex(
        (el: any) => el.columnId === ci
      );
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const modifyCellValues = (
    modifyBy: number | undefined,
    operation: "multiply" | "add" | "fixed" | undefined
  ) => {
    let newData: any = [...dummyData];
    for (let column of selectedRange[0].columns) {
      for (let row of selectedRange[0].rows) {
        let cell = row.cells.find((c: any) => c.groupId === column.columnId);
        if (
          (cell != null && typeof cell.value == "number") ||
          cell.text == ""
        ) {
          const { groupId, value } = cell;
          const { rowId } = row;
          if (modifyBy) {
            switch (operation) {
              case "multiply":
                newData[rowId][groupId] = value * modifyBy;
                break;
              case "add":
                newData[rowId][groupId] =
                  (value == undefined ? 0 : value) + Number(modifyBy);
                break;
            }
          }
        }
      }
    }
    props.fileChange(true);
    setDummydata(newData);
  };

  const handleCellChange = (changedCells: any) => {
    let newData: any = [...dummyData];
    changedCells.forEach(({ rowId, columnId, newCell }: any) => {
      if (newCell && !newCell.readOnly) {
        switch (newCell.type) {
          case "number":
            newData[rowId][columnId] = newCell.value;
            break;
          case "text":
            newData[rowId][columnId] = newCell.text;
            break;
        }
      }
    });
    props.fileChange(true);
    setDummydata(newData);
  };

  const handleContextMenu = (event: any) => {
    setSortMenuVisible(false);
    setAddAmountVisible(false);
    event.preventDefault();
    const dataset = event.target.dataset;
    if (
      dataset.cellColidx &&
      dataset.cellRowidx &&
      dataset.cellRowidx !== "0"
    ) {
      if (
        selectedRange[0]?.rows.some(
          (item: any) => item.rowId === dataset.cellRowidx - 1
        ) &&
        selectedRange[0]?.columns.some(
          (item: any) => item.idx === dataset.cellColidx
        )
      ) {
        setSelectedCell({
          columnId: columns[dataset.cellColidx].columnId,
          rowId: dataset.cellRowidx - 1,
        });
      }
      setMenuVisible(true);
      setMenuPosition({ x: event.pageX, y: event.pageY });
    }
  };

  const handleSelectionChange = (newSelection: any) => {
    setSelectedRange(newSelection);
    props.onSelect(newSelection);
  };

  const handleSortVisible = (event: any) => {
    if (event.target.dataset.cellRowidx === "0") {
      event.preventDefault();
      setSortMenuPosition({
        x: event.pageX,
        y: event.pageY,
      });
      setSortMenuVisible(true);
    } else {
      setSortMenuVisible(false);
    }
  };

  const handleSort = (column: any, rows: any, sortOrder: any) => {
    const columnIndex = rows[0].cells.findIndex(
      (cell: any) => cell.groupId === column.columnId
    );

    const dataRows = rows.filter((row: any) => row.rowId !== "summary");
    const summaryRow = rows.find((row: any) => row.rowId === "summary");

    dataRows.sort((a: any, b: any) => {
      if (a.rowId === "header" || b.rowId === "header") return 0;

      const cellA = a.cells[columnIndex];
      const cellB = b.cells[columnIndex];

      let valueA = cellA.value !== undefined ? cellA.value : cellA.text;
      let valueB = cellB.value !== undefined ? cellB.value : cellB.text;

      if (typeof valueA === "string" && typeof valueB === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (sortOrder === "ascending") {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else if (sortOrder === "descending") {
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
      }

      return 0;
    });

    const headerCell = rows[0].cells.find(
      (cell: any) => cell.groupId === column.columnId
    );

    headerCell.sortDirection = sortOrder === "ascending" ? "asc" : "desc";
    rows[0].cells
      .filter((cell: any) => cell.groupId !== column.columnId)
      .forEach((c: any) => {
        c.sortDirection = undefined;
      });

    const sortedRows = [...dataRows];
    if (summaryRow) {
      sortedRows.push(summaryRow);
    }

    setRows(sortedRows);
  };

  const handleFilterOptions = (column: any, rows: any) => {
    const columnIndex = rows[0].cells.findIndex(
      (cell: any) => cell.groupId === column.columnId
    );
    const cellType = rows[1]?.cells[columnIndex];
    if (cellType) {
      if (cellType.type === "number") {
        setFilterOptions(
          defaultFilterOptions.filter((option) => option.value !== "contains")
        );
      } else if (cellType.type === "text") {
        setFilterOptions(
          defaultFilterOptions.filter(
            (option) => option.value === "equals" || option.value === "contains"
          )
        );
      } else {
        setFilterOptions(defaultFilterOptions);
      }
    }
  };

  const handleFilter = (filterOption: any) => {
    setFilterOption(filterOption);
  };

  const handleFilterApply = (column: any, rows: any) => {
    const columnIndex = rows[0].cells.findIndex(
      (cell: any) => cell.groupId === column.columnId
    );

    const filteredColumn = rows.filter((row: any) => {
      if (row.rowId === "header") return true;
      const cell = row.cells[columnIndex];
      if (filterValue)
        switch (filterOption.value) {
          case "morethan":
            return typeof cell.value === "number" && cell.value > filterValue;
          case "lessthan":
            return typeof cell.value === "number" && cell.value < filterValue;
          case "equals":
            return (
              (typeof cell.value === "number" && cell.value == filterValue) ||
              (typeof cell.text === "string" &&
                cell.text.toLowerCase() ===
                  filterValue.toString().toLowerCase())
            );
          case "contains":
            return (
              typeof cell.text === "string" && cell.text.includes(filterValue)
            );
          default:
            return false;
        }
    });

    const headerCell = rows[0].cells.find(
      (cell: any) => cell.groupId === column.columnId
    );

    headerCell.isFiltered = true;

    setRows(filteredColumn);
    setFilterMenuVisible(false);
  };

  const handleFilerClear = () => {
    setFilterValue(undefined);
    if (columns.length !== 0) setRows(getRows(dummyData));
    setFilterMenuVisible(false);
  };

  const handleAddAmount = (type: string) => {
    switch (type) {
      case "multiply":
        setAddAmountHeader("Multiply by amount");
        setAddAmountType("multiply");
        break;
      case "static":
        setAddAmountHeader("Add static sum");
        setAddAmountType("add");
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    getColumns();
  }, [dummyData]);

  useEffect(() => {
    setDummydata(props.data.value);
    if (!props.dataLoading) {
      setSortMenuVisible(false);
      setFilterMenuVisible(false);
      setAddAmountVisible(false);
      setMenuVisible(false);
      setSelectedCell(undefined);
    }
  }, [props.data]);

  React.useEffect(() => {
    if (columns.length !== 0) setRows(getRows(dummyData));
  }, [columns]);

  return (
    <div
      className="tableContainer"
      onContextMenu={handleContextMenu}
      onClick={(e: any) => {
        e.preventDefault();
        setMenuVisible(false);
        handleSortVisible(e);
        setFilterOption({ value: "equals", label: "Equals" });
        setFilterValue(undefined);
        setFilterMenuVisible(false);
        setAddAmountVisible(false);
        setAddAmountValue(undefined);
      }}>
      {menuVisible && (
        <ContextMenu
          x={menuPosition.x}
          y={menuPosition.y}
          multiplication={(e: any) => {
            handleAddAmount("multiply");
            setAddAmountVisible(true);
            setMenuVisible(false);
            e.stopPropagation();
          }}
          add={(e: any) => {
            handleAddAmount("static");
            setAddAmountVisible(true);
            setMenuVisible(false);
            e.stopPropagation();
          }}
        />
      )}
      {addAmountVisible && (
        <AddAmount
          header={addAmountHeader}
          x={menuPosition.x}
          y={menuPosition.y}
          value={addAmountValue}
          onInputChange={(event: any) => {
            setAddAmountValue(event.target.value);
          }}
          handleClick={(e: Event) => e.stopPropagation()}
          onApply={() => {
            modifyCellValues(addAmountValue, addAmountType);
            setAddAmountValue(undefined);
            setAddAmountVisible(false);
          }}
        />
      )}
      {sortMenuVisible && (
        <SortMenu
          x={sortMenuPosition.x}
          y={sortMenuPosition.y}
          ascend={() => handleSort(selectedColumn, rows, "ascending")}
          descend={() => handleSort(selectedColumn, rows, "descending")}
          filter={(e: any) => {
            setSortMenuVisible(false);
            setFilterMenuVisible(true);
            handleFilterOptions(selectedColumn, rows);
            e.stopPropagation();
          }}
        />
      )}
      {filterMenuVisible && (
        <FilterMenu
          x={sortMenuPosition.x}
          y={sortMenuPosition.y}
          onApply={() => handleFilterApply(selectedColumn, rows)}
          onClear={handleFilerClear}
          onFilterChange={(option: any) => handleFilter(option)}
          inputValue={filterValue}
          onInputChange={(event: any) => {
            setFilterValue(event.target.value);
          }}
          filterOptions={filterOptions}
        />
      )}
      {columns.length !== 0 && rows.length !== 0 && (
        <ReactGrid
          rows={rows}
          columns={columns}
          stickyLeftColumns={
            props.selectedTab === "Budget" || props.selectedTab === "Original"
              ? 8
              : props.selectedTab === "FTE"
              ? 2
              : 1
          }
          stickyTopRows={1}
          customCellTemplates={{
            sortableHeader: new SortableHeaderCellTemplate(),
            summary: new SummaryCellTemplate(),
          }}
          onColumnResized={handleColumnResize}
          enableRowSelection={true}
          focusLocation={selectedCell}
          onFocusLocationChanging={(location: any) => {
            if (location.rowId === "header") {
              setSelectedColumn(location);
              return true;
            }
            setSelectedCell(location);
            return true;
          }}
          onCellsChanged={handleCellChange}
          onSelectionChanged={handleSelectionChange}
          enableRangeSelection={true}
        />
      )}
    </div>
  );
});

export default Table;
