import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`, // Replace the placeholder with your tenant subdomain
    redirectUri: "/home", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  endpoint: "https://graph.microsoft.com/",
  scopes: ["offline_access", "openid", "User.Read"],
};
