import * as React from "react";

import { getCellProperty } from "@silevis/reactgrid";
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
} from "@silevis/reactgrid";

export interface SummaryCell extends Cell, Span {
  type: "summary";
  value?: number;
  text: string;
  groupId?: string;
}

export class SummaryCellTemplate implements CellTemplate<SummaryCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<SummaryCell>
  ): Compatible<SummaryCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  render(
    cell: Compatible<SummaryCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SummaryCell>, commit: boolean) => void
  ): React.ReactNode {
    return cell.text;
  }

  isFocusable = (cell: Compatible<SummaryCell>): boolean => true;

  getClassName(cell: Compatible<SummaryCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : "";
  }

  getStyle = (cell: Compatible<SummaryCell>): CellStyle => ({});
}
