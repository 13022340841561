import "./SortMenu.css";
import SortMenuOption from "./SortMenuOption";

interface SortMenuProps {
  x: number;
  y: number;
  ascend: () => void;
  descend: () => void;
  filter: any;
}

const SortMenu = (props: SortMenuProps) => {
  return (
    <div className="sortMenu" style={{ top: props.y, left: props.x }}>
      <SortMenuOption
        label={"Sort Ascending"}
        img={{ src: "Arrow Up.png", alt: "up" }}
        onClick={props.ascend}
      />
      <SortMenuOption
        label={"Sort Descending"}
        img={{ src: "Arrow Down.png", alt: "down" }}
        onClick={props.descend}
      />
      <SortMenuOption
        label={"Filter by"}
        img={{ src: "Filter.png", alt: "filter" }}
        onClick={props.filter}
      />
    </div>
  );
};

export default SortMenu;
