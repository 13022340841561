import { useState } from "react";
import "./ContextMenu.css";

type ImageProps = {
  src: string;
  alt: string;
};

interface ContextMenuOptionProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  img: ImageProps;
}

const ContextMenuOption = (props: ContextMenuOptionProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <div
      className={hovered ? "contextMenuOptionHovered" : "contextMenuOption"}
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className="contextMenuOptionIcon"
        src={props.img.src}
        alt={props.img.alt}
      />
      <div>{props.label}</div>
    </div>
  );
};

export default ContextMenuOption;
