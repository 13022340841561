import React, { useEffect, useRef } from "react";
import "./AddAmount.css";
import TabBubble from "../TableTabs/TabBubble";

interface addAmountProps {
  header: string | undefined;
  x: number;
  y: number;
  value: number | undefined;
  onInputChange: any;
  handleClick: any;
  onApply: any;
}

const AddAmount = (props: addAmountProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onApply();
    }
  };

  return (
    <div
      className="addAmountMenu"
      onClick={props.handleClick}
      style={{ top: props.y, left: props.x }}>
      <div className="addAmountHeader">{props.header}</div>
      <input
        placeholder="Add number"
        value={props.value}
        className="addAmountText"
        onChange={props.onInputChange}
        onKeyDown={handleKeyPress}
        ref={inputRef}
      />
      <div className="applyButton">
        <TabBubble name="Apply" selected="Apply" onClick={props.onApply} />
      </div>
    </div>
  );
};

export default AddAmount;
