import { useMsal } from "@azure/msal-react";
import "./Header.css";
import { useState } from "react";

interface HeaderProps {
  name: string | undefined;
}

const Header = (props: HeaderProps) => {
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { instance } = useMsal();

  return (
    <div className="header">
      <img src="pentalogo.png" alt="procarelogo" className="logo" />
      <div>
        <div
          className="logoutContainer"
          onClick={() => setLogoutOpen(!logoutOpen)}>
          <div className="userName">{props.name}</div>
          <img className="logoutArrow" src="Chevron.png" alt="chevron" />
        </div>
        {logoutOpen && (
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={hovered ? "logout hovered" : "logout"}
            onClick={() =>
              instance.logoutRedirect({ postLogoutRedirectUri: "/" })
            }>
            <img className="logoutArrow" src="Sign Out.png" alt="signout" />
            <span style={{ marginBottom: "4px" }}>Log out</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
