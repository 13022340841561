import { useEffect, useState } from "react";
import "./Dropdown.css";

interface Option {
  label: string;
  value: string;
}

interface DropdownProps {
  filterOptions: Option[];
  defaultValue?: Option;
  selectedOptions?: Option[];
  onSelect: (selected: Option | Option[]) => void;
  multiselect: boolean;
  open: boolean;
  setOpen: any;
}

const Dropdown = (props: DropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    props.selectedOptions ? props.selectedOptions : []
  );

  const handleSelect = (option: Option) => {
    if (props.multiselect) {
      setSelectedOptions((prevSelected) => {
        if (prevSelected.some((selected) => selected.value === option.value)) {
          const newSelected = prevSelected.filter(
            (selected) => selected.value !== option.value
          );
          props.onSelect(newSelected);
          return newSelected;
        } else {
          const newSelected = [...prevSelected, option];
          props.onSelect(newSelected);
          return newSelected;
        }
      });
    } else {
      setSelectedOptions([option]);
      props.onSelect(option);
    }
  };

  const renderSelectedOptions = () => {
    if (props.multiselect) {
      return selectedOptions.map((option) => option.label).join(", ");
    } else {
      return selectedOptions[0] ? selectedOptions[0].label : "Select an option";
    }
  };

  useEffect(() => {
    if (props.selectedOptions) {
      setSelectedOptions(props.selectedOptions);
    }
  }, [props.selectedOptions]);

  return (
    <div className="dropdown">
      <div
        className="dropdownHeader"
        onClick={(e: any) => {
          props.setOpen(!props.open);
          e.stopPropagation();
        }}>
        <div className="dropdownText">{renderSelectedOptions()}</div>
        <i className="arrow" />
      </div>
      {props.open && (
        <div className="dropdownOptions">
          {props.filterOptions.map((option) => (
            <div
              key={option.value}
              className="dropdownOption"
              onClick={
                props.multiselect
                  ? (e: any) => {
                      handleSelect(option);
                      e.stopPropagation();
                    }
                  : () => handleSelect(option)
              }>
              {props.multiselect &&
                selectedOptions.some(
                  (selected) => selected.value === option.value
                ) && <span>✓ </span>}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
