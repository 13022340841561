import React from "react";
import "./SavePopup.css";
import TabBubble from "../TableTabs/TabBubble";

const SavePopup = (props: any) => {
  return (
    <div className="saveBackground" onClick={props.onClose}>
      <div className="savePopup" onClick={(e: any) => e.stopPropagation()}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="saveHeader">Are you sure you want to leave?</span>
          <span className="saveText">All unsaved changes will be deleted.</span>
        </div>
        <div className="saveButtons">
          <TabBubble name="Quit" selected="Quit" onClick={props.onQuit} />
          <TabBubble name="Cancel" selected="" onClick={props.onClose} />
        </div>
      </div>
    </div>
  );
};

export default SavePopup;
