import React, { useEffect, useState } from "react";
import "./AssignMenu.css";
import TabBubble from "../TableTabs/TabBubble";
import { useMsal } from "@azure/msal-react";
import useDataverseService from "../../dataverseService";

const AssignMenu = (props: any) => {
  const { instance } = useMsal();
  const { getUsers, fetchData } = useDataverseService();
  const [users, setUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  useEffect(() => {
    const fetchUsers = async () => {
      const usersList = await getUsers();
      setUsers(usersList);

      const currentUser = usersList.find((u: any) =>
        u.firstname === "#"
          ? u.fullname.replace(/^# /, "") === instance.getActiveAccount()?.name
          : u.fullname === instance.getActiveAccount()?.name
      );

      const owners: any = await fetchData("new_entityowners");

      const isCurrentUserOwner =
        props.table._ownerid_value === currentUser.systemuserid;

      if (isCurrentUserOwner) {
        setUsers(usersList);
      } else {
        const selectedTableOwner = owners.data.value.find(
          (o: any) => o._ownerid_value === props.table._ownerid_value
        );

        if (selectedTableOwner) {
          const filteredUsersList = usersList.filter(
            (u: any) => u.systemuserid === selectedTableOwner._ownerid_value
          );
          setUsers(filteredUsersList);
        }
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setFilteredUsers(
      users.filter((user) =>
        user.fullname.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleUserSelect = (user: any) => {
    setSelectedUser(user);
    setInputValue(user.displayName);
    setFilteredUsers([]);
  };

  const getInitials = (name: string) => {
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("");
    return initials.toUpperCase();
  };

  const handleAllRecordsChange = () => {
    props.handleAllRecords();
  };

  const handleSelectedRecordsChange = () => {
    props.handleSelectedRecords();
  };

  return (
    <div
      className="assignMenu"
      style={{ top: props.y, left: props.x }}
      onClick={(e: any) => e.stopPropagation()}>
      <div className="assignHeader">
        Assign to
        <img
          style={{ cursor: "pointer" }}
          src="Dismiss.png"
          alt="dismiss"
          height={"16px"}
          onClick={() => props.onDismiss(false)}
        />
      </div>
      <input
        className="assignInput"
        placeholder="Search..."
        value={inputValue}
        onChange={handleInputChange}
      />
      {filteredUsers.length > 0 && (
        <div className="assignDropdown">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="assignDropdownItem"
              onClick={() => handleUserSelect(user)}>
              <span>{user.fullname}</span>
              <span className="personPosition">{user.title}</span>
            </div>
          ))}
        </div>
      )}
      {selectedUser && (
        <>
          <div className="assignPerson">
            <div className="initialCircle">
              {getInitials(selectedUser.fullname)}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{selectedUser.fullname}</span>
              <span className="personPosition">{selectedUser.title}</span>
            </div>
          </div>
        </>
      )}
      <div className="assignCheckboxContainer">
        <div className="assignCheckbox">
          <input
            type="checkbox"
            className="box"
            id="allRecords"
            checked={props.allRecordsSelected}
            onChange={handleAllRecordsChange}
          />
          <label htmlFor="allRecords">All records</label>
        </div>
        <div className="assignCheckbox">
          <input
            type="checkbox"
            className="box"
            id="selectedRecords"
            checked={props.selectedRecordsSelected}
            onChange={handleSelectedRecordsChange}
          />
          <label htmlFor="selectedRecords">Selected records</label>
        </div>
      </div>
      <div className="assignButton">
        <TabBubble
          name="Assign"
          selected={
            (props.allRecordsSelected || props.selectedRecordsSelected) &&
            selectedUser
              ? "Assign"
              : ""
          }
          onClick={
            (props.allRecordsSelected || props.selectedRecordsSelected) &&
            selectedUser
              ? () => {
                  props.onAssign(
                    selectedUser,
                    props.selectedRecordsSelected,
                    props.allRecordsSelected
                  );
                  props.onDismiss(false);
                }
              : () => {}
          }
        />
      </div>
    </div>
  );
};

export default AssignMenu;
