import { Column, Id, ReactGrid, Row } from "@silevis/reactgrid";
import React, { forwardRef, useEffect } from "react";
import { SortableHeaderCellTemplate } from "../Components/CellTemplates/SortableHeaderCell";
import { SummaryCellTemplate } from "../Components/CellTemplates/SummaryCell";
import "./SummaryTable.css";

interface TableProps {
  originalData: any;
  data: any;
  selected: any;
  selectedTab: string;
  dataLoading: boolean;
  onSelect: (selectedRows: any[]) => void;
  fileChange?: any;
  columns?: any;
  rows?: any;
  tableName?: any;
}

const SummaryTable = forwardRef((props: TableProps) => {
  const [dummyData, setDummydata] = React.useState<any[]>(props.data.value);
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [rows, setRows] = React.useState<Row[]>([]);
  const rowHeight = 35;

  const getColumns = () => {
    const uniqueColumns = new Set<string>();
    props.columns.forEach((item: any) => {
      uniqueColumns.add(item);
    });

    let columnsArray: Column[] = [];

    uniqueColumns.forEach((key) =>
      columnsArray.push({
        columnId: key,
        width: 100,
        resizable: true,
      })
    );

    columnsArray.push({
      columnId: "Summary",
      width: 100,
      resizable: true,
    });

    setColumns(columnsArray);
  };

  const formatNumber = (num: number): string => {
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  console.log(rows);

  const getHeaderRow = React.useCallback((): Row => {
    const rowNames: any[] = [];
    columns.forEach((c: any) => {
      let headerText = c.columnId;
      rowNames.push({
        type: "sortableHeader",
        text: headerText,
        groupId: c.columnId,
        isFiltered: false,
      });
    });
    return {
      rowId: "header",
      height: rowHeight,
      cells: rowNames,
    };
  }, [columns]);

  const calculateSummaries = (rows: any) => {
    const summaries: any = {};

    rows.forEach((row: any) => {
      row.cells.forEach((cell: any) => {
        if (cell.type === "number" || cell.type === "summary") {
          if (!summaries[cell.groupId]) {
            summaries[cell.groupId] = 0;
          }
          summaries[cell.groupId] += parseFloat(cell.value);
        }
      });
    });

    Object.keys(summaries).forEach((key) => {
      summaries[key] = parseFloat(summaries[key].toFixed(2)).toString();
    });

    return summaries;
  };

  const createSummaryRow = (summaries: any, columns: any) => {
    const cells = columns.map((column: any) => {
      const summaryValue = summaries[column.columnId];
      return {
        type: "summary",
        value: formatNumber(parseFloat(summaryValue)) || "",
        text:
          column.columnId === ""
            ? "Summary"
            : summaryValue
            ? formatNumber(parseFloat(summaryValue)).toString()
            : "",
        groupId: column.columnId,
      };
    });
    return {
      rowId: "summary",
      height: rowHeight,
      cells: cells,
    };
  };

  const getRows = (people: any[]): any[] => {
    const distinctValues = props.rows?.value.map((item: any) => {
      if (item.hasOwnProperty("cr864_asisproject")) {
        return item.cr864_asisproject;
      } else if (item.hasOwnProperty("cr864_unifiedcategory")) {
        if (
          item.cr864_unifiedcategory == "Sestra" ||
          item.cr864_unifiedcategory == "Lekár"
        ) {
          return item.cr864_unifiedcategory.toLowerCase();
        } else {
          return item.cr864_unifiedcategory;
        }
      } else {
        return "Iné";
      }
    });

    const priorityList = ["lekár", "sestra", "OZP", "THP", "R", "Iné"];

    const sortedDistinctValues = distinctValues.sort((a: any, b: any) => {
      const aIndex = priorityList.indexOf(a);
      const bIndex = priorityList.indexOf(b);
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      } else if (aIndex !== -1) {
        return -1;
      } else if (bIndex !== -1) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });

    const sums: any = {};
    sortedDistinctValues.forEach((value: string) => {
      sums[value] = {};
      columns.forEach((column: any) => {
        sums[value][column.columnId] = 0;
      });
    });

    sortedDistinctValues.forEach((distinctValue: string) => {
      const filteredData = props.data.value.filter((item: any) => {
        if (distinctValue == "Iné")
          return (
            item["Zjednotená kategória"] != "lekár" &&
            item["Zjednotená kategória"] != "sestra" &&
            item["Zjednotená kategória"] != "OZP" &&
            item["Zjednotená kategória"] != "THP" &&
            item["Zjednotená kategória"] != "R" &&
            item["Zjednotená kategória"] != "Lekár" &&
            item["Zjednotená kategória"] != "Sestra"
          );
        return (
          item["ASIS/PROJEKT"] === distinctValue ||
          (item["Zjednotená kategória"] === "Lekár" ||
          item["Zjednotená kategória"] === "Sestra"
            ? item["Zjednotená kategória"].toLowerCase() === distinctValue
            : item["Zjednotená kategória"] === distinctValue)
        );
      });

      filteredData.forEach((item: any) => {
        columns.forEach((column: any) => {
          const sumField = `sum${column.columnId
            .replace("Januar", "01")
            .replace("Februar", "02")
            .replace("Marec", "03")
            .replace("April", "04")
            .replace("Maj", "05")
            .replace("Jun", "06")
            .replace("Jul", "07")
            .replace("August", "08")
            .replace("September", "09")
            .replace("Oktober", "10")
            .replace("November", "11")
            .replace("December", "12")}`;
          if (item.hasOwnProperty(sumField)) {
            sums[distinctValue][column.columnId] += item[sumField] || 0;
          }
        });
      });
    });

    const dataRows = sortedDistinctValues.map(
      (distinctValue: any, idx: any) => {
        let rowSummary = 0;
        let cells: any[] = columns.map((column: any, colIdx: any) => {
          let value;
          if (colIdx === 0) {
            value = distinctValue;
          } else {
            value = sums[distinctValue][column.columnId] || 0;
            rowSummary += value;
          }

          const isSticky = colIdx === 0;

          if (typeof value === "number") {
            return {
              type: "number",
              value: value,
              groupId: column.columnId,
              readOnly: isSticky,
            };
          } else if (typeof value === "string") {
            return {
              type: "text",
              text: value,
              groupId: column.columnId,
              readOnly: isSticky,
            };
          } else {
            return {
              type: "text",
              text: "",
              groupId: column.columnId,
              readOnly: isSticky,
            };
          }
        });

        const summaryCellIndex = cells.findIndex(
          (cell) => cell.groupId === "Summary"
        );
        if (summaryCellIndex !== -1) {
          cells[summaryCellIndex].value = rowSummary;
          cells[summaryCellIndex].text = rowSummary.toString();
        } else {
          cells.push({
            type: "number",
            value: rowSummary,
            groupId: "Summary",
            readOnly: true,
          });
        }

        return {
          rowId: idx,
          height: rowHeight,
          cells: cells,
        };
      }
    );

    const summaries = calculateSummaries(dataRows);
    const summaryRow = createSummaryRow(summaries, columns);

    return [getHeaderRow(), ...dataRows, summaryRow];
  };

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns: any) => {
      const columnIndex = prevColumns.findIndex(
        (el: any) => el.columnId === ci
      );
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  React.useEffect(() => {
    getColumns();
  }, [dummyData]);

  useEffect(() => {
    setDummydata(props.data.value);
  }, [props.data]);

  React.useEffect(() => {
    if (columns.length !== 0) setRows(getRows(dummyData));
  }, [columns]);

  return (
    <div
      className="tableContainer"
      onClick={(e: any) => {
        e.preventDefault();
      }}>
      {columns.length !== 0 && rows.length !== 0 && (
        <ReactGrid
          rows={rows}
          columns={columns}
          stickyLeftColumns={1}
          stickyTopRows={1}
          customCellTemplates={{
            sortableHeader: new SortableHeaderCellTemplate(),
            summary: new SummaryCellTemplate(),
          }}
          onColumnResized={handleColumnResize}
          enableRangeSelection={true}
        />
      )}
    </div>
  );
});

export default SummaryTable;
