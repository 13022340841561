import React, { useState } from "react";
import "./SortMenu.css";

const SortMenuOption = (props: any) => {
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <div
      className={hovered ? "sortMenuOptionHovered" : "sortMenuOption"}
      onClick={props.onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <img
        className="sortMenuOptionIcon"
        src={props.img.src}
        alt={props.img.alt}
      />
      <div>{props.label}</div>
    </div>
  );
};

export default SortMenuOption;
