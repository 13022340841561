import React from "react";
import "./SubmitPopup.css";
import TabBubble from "../TableTabs/TabBubble";

interface SubmitPopupProps {
  onClose: () => void;
  onSubmit: () => void;
}

const SubmitPopup: React.FC<SubmitPopupProps> = ({ onClose, onSubmit }) => {
  return (
    <div className="submitPopupOverlay" onClick={onClose}>
      <div
        className="submitPopupContent"
        onClick={(e: any) => e.stopPropagation()}>
        <div className="submitPopupHeader">
          <span>Are you sure you want to submit?</span>
        </div>
        <div className="submitPopupBody">
          <span>
            If you submit this budget, you will no longer be able to make
            changes
          </span>
        </div>
        <div className="submitPopupFooter">
          <TabBubble name="Submit" selected="Submit" onClick={onSubmit} />
          <TabBubble name="Cancel" selected="" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default SubmitPopup;
