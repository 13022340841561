import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import "./FilterMenu.css";
import TabBubble from "../TableTabs/TabBubble";
import Dropdown from "../Dropdown/Dropdown";

interface FilterMenuProps {
  y: number;
  x: number;
  onFilterChange: (selectedOption: any) => void;
  filterOptions: any[];
  inputValue: string | number | undefined;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApply: () => void;
  onClear: () => void;
}

const FilterMenu = (props: FilterMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onApply();
    }
  };

  return (
    <div
      onClick={(e: any) => {
        e.stopPropagation();
        setDropdownOpen(false);
      }}
      className="filterMenu"
      style={{ top: props.y, left: props.x }}>
      <div className="filterHeader">
        <img className="filterMenuIcon" src="Filter.png" alt="filter" />
        Filter by
      </div>
      <div className="filterInputs">
        <Dropdown
          multiselect={false}
          onSelect={props.onFilterChange}
          filterOptions={props.filterOptions}
          defaultValue={{
            value: "equals",
            label: "Equals",
          }}
          open={dropdownOpen}
          setOpen={setDropdownOpen}
        />
        <input
          placeholder="..."
          className="filterText"
          value={props.inputValue}
          onChange={props.onInputChange}
          onKeyDown={handleKeyPress}
          ref={inputRef}
        />
      </div>
      <div className="filterButtons">
        <TabBubble name="Apply" selected="Apply" onClick={props.onApply} />
        <TabBubble name="Clear" selected="" onClick={props.onClear} />
      </div>
    </div>
  );
};

export default FilterMenu;
