import React from "react";
import "./GroupFilter.css";

const GroupFilter = (props: any) => {
  return props.filters.map((item: any) => (
    <div className="groupFilter">
      <span>{item.label}</span>
      <img
        className="groupFilterImage"
        src="Dismiss.png"
        alt="dismiss"
        onClick={() => {
          props.onDelete(item.value);
        }}
      />
    </div>
  ));
};

export default GroupFilter;
